.header {
  display: flex;
  justify-content: space-between;
  padding: 6vw 4vw;
  align-items: center;
}
.header img {
  background: gray;
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
}
.hdInfo {
  margin-left: 3vw;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.hdInfo p {
  color: #fff;
  font-weight: 600;
  font-size: 4vw;
}
.hdInfo span {
  color: #606383;
  font-size: 3vw;
}
.buttonHd {
  background: linear-gradient(270deg, #00c28b 0%, #0cd66a 100%);
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 3.5vw;
  padding: 3vw 4vw;
  border-radius: 2vw;
}
.buttonHd svg {
  width: 6vw;
  height: 6vw;
  margin-right: 1vw;
}
