.subscribeElem {
  display: flex;
  margin-top: 4vw;
  background: #35374a;
  padding: 2vw;
  border-radius: 3vw;
}
.subscribeElem img {
  width: 26vw;
  margin-right: 6vw;
  object-fit: cover;
}
.subscribeElem article {
  text-align: right;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 4.5vw;
  color: #606383;
}
.Subscribe h3 {
  text-align: center;
  color: #fff;
  font-size: 7vw;
  margin-top: 4vw;
  font-weight: 600;
}
.subsBody {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subscribeElem p {
  color: #fff;
  font-size: 6vw;
  font-weight: 600;
}
.subscribeElem span {
  color: #606383;
  font-size: 4.5vw;
  font-weight: 600;
}
.sbsBtn {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 3vw 4vw;
  border-radius: 2vw;
  background: linear-gradient(269.53deg, #8052ff -19.46%, #428eff 140.71%);
  width: fit-content;
  margin-top: 3.5vw;
}
.subscribeElem {
  cursor: pointer;
  transition: 0.5s;
}
