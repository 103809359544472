.channels img {
  width: 100%;
  border-radius: 3vw;
  object-fit: cover;
  display: block;
}
.Main {
  padding: 0 4vw;
}
.channels {
  width: 92vw;
  margin: 0 auto;
  border-radius: 7vw;
  overflow: hidden;
}
.swiper-slide {
  position: relative;
}
.watch {
  position: absolute;
  top: 50%;
  left: 50%;
  background: linear-gradient(270deg, #00c28b 0%, #004e24 100%);
  padding: 4vw;
  border-radius: 9vw;
  color: #fff;
  font-size: 4vw;
  align-items: center;
  display: flex;
  translate: -50% -50%;
  width: 70%;
  text-align: center;
  cursor: pointer;
}
@keyframes zooming {
  0%,
  100% {
    scale: 1;
  }
  50% {
    scale: 1.05;
  }
}
.dots svg {
  margin: 0 2vw;
  cursor: pointer;
  width: 8vw;
  height: 8vw;
}
.watch {
  animation: zooming 4s infinite;
}
.watch svg {
  margin-right: 2vw;
  width: 7vw;
  height: 7vw;
}
.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4vw 0;
}
.dot {
  width: 4.5vw;
  height: 4.5vw;
  border-radius: 50%;
  background: #35374a;
  margin: 1.5vw;
}
.time {
  margin: 0 auto;
  padding: 5vw 0;
  border-radius: 12vw;
  text-align: center;
  width: 70vw;
  font-size: 4vw;
  color: #fff;
  font-weight: 600;
  margin-bottom: 6vw;
  border: 2px solid #00c28b;
}
.grs {
  width: 8vw;
  height: 8vw;
  margin: 0 auto;
  display: block;
  margin-bottom: 6vw;
}
