@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
body {
  background: #232535;
  padding-bottom: 8vw;
  max-width: 100vw;
  overflow-x: hidden;
}
.App {
  max-width: 100vw;
}
