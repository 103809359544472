.channel {
  padding: 0 4vw;
}
.channel video {
  border-radius: 3vw;
  width: 100%;
  height: 55vw;
  object-fit: cover;
}
.channel iframe {
  width: 100%;
  height: 52vw;
  border-radius: 2vw;
}
.videoplayer_btn_vk_video {
  display: none !important;
}
.channel {
  animation: add 0.5s;
}
@keyframes add {
  0% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
