.rightAdmin h3 {
  font-size: 2vw;
  color: #606383;
  margin-bottom: 1vw;
  cursor: pointer;
}
.rightAdmin {
  margin-left: 4vw;
}
.admin {
  padding: 2vw;
}
.form {
  display: flex;
  flex-direction: column;
  width: 35vw;
}
.form input {
  padding: 1vw;
  background: #606383;
  color: #fff;
  border-radius: 6vw;
  font-size: 0.9vw;
  border: none;
  outline: none;
}
.form *::placeholder {
  color: #fff;
}
.form textarea {
  margin-top: 1vw;
  margin-bottom: 1vw;
  padding: 1vw;
  background: #606383;
  color: #fff;
  border-radius: 1vw;
  font-size: 0.9vw;
  border: none;
  outline: none;
}
.guZdik {
  width: 100%;
  max-width: 100% !important;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vw;
}
.buttons .save {
  background: #00c28b;
  color: #fff;
  padding: 1vw;
  border-radius: 6vw;
  cursor: pointer;
  font-size: 0.9vw;
  width: 48%;
  text-align: center;
}
.buttons .back {
  border: 1px solid #fff;
  color: #fff;
  padding: 1vw;
  border-radius: 6vw;
  cursor: pointer;
  text-align: center;
  width: 48%;
  font-size: 0.9vw;
}
.adminBody {
  display: flex;
}
.channelItem {
  background: #606383;
  border-radius: 1vw;
  padding: 1vw;
  cursor: pointer;
  margin-bottom: 1vw;
  transition: 0.3s;
}
.channelItem:hover {
  transform: translateY(-3px);
}
.channelItem img {
  border-radius: 1vw;
  width: 12vw;
  margin-right: 1vw;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  object-fit: cover;
}
.channelItem p {
  color: #fff;
  font-weight: bold;
  font-size: 1.5vw;
  margin-bottom: 1vw;
}
.channelItem {
  display: flex;
}
.channelItem iframe {
  width: 25vw;
  height: 14vw;
  border-radius: 1vw;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
.leftAdmin h3 {
  color: #fff;
  font-size: 2vw;
  margin-bottom: 1vw;
}
.channelItem p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goto {
  text-decoration: none;
  font-size: 0.9vw;
  padding: 1vw;
  background: #fff;
  color: #00c28b;
  border-radius: 0.5vw;
}
@media (max-width: 640px) {
  .adminBody {
    flex-direction: column-reverse;
  }
  .rightAdmin {
    margin-left: 0;
  }
  .admin {
    padding: 4vw;
  }
  .rightAdmin h3 {
    font-size: 7vw;
    color: #606383;
    margin-bottom: 5vw;
    margin-top: 5vw;
    cursor: pointer;
  }
  .form input {
    padding: 4vw;
    background: #606383;
    color: #fff;
    border-radius: 6vw;
    font-size: 3.9vw;
    border: none;
    outline: none;
    margin-bottom: 4vw;
  }
  .form {
    width: 100%;
  }
  .goto {
    text-decoration: none;
    font-size: 3.9vw;
    padding: 4vw;
    background: #fff;
    color: #00c28b;
    border-radius: 2.5vw;
  }
  .form textarea {
    margin-top: 1vw;
    margin-bottom: 5vw;
    padding: 4vw;
    background: #606383;
    color: #fff;
    border-radius: 4vw;
    font-size: 3.9vw;
    border: none;
    outline: none;
  }
  .buttons {
    flex-direction: column;
    margin-top: 8vw;
  }
  .buttons .save {
    background: #00c28b;
    color: #fff;
    padding: 4vw;
    border-radius: 6vw;
    cursor: pointer;
    font-size: 3.9vw;
    width: 100%;
    text-align: center;
  }
  .buttons .back {
    border: 1px solid #fff;
    color: #fff;
    padding: 4vw;
    border-radius: 6vw;
    cursor: pointer;
    text-align: center;
    width: 100%;
    margin-top: 6vw;
    font-size: 3.9vw;
  }
  .leftAdmin {
    margin-top: 8vw;
  }
  .leftAdmin h3 {
    color: #fff;
    font-size: 7vw;
    margin-bottom: 4vw;
  }
  .channelItem {
    background: #606383;
    border-radius: 3vw;
    padding: 5vw;
    cursor: pointer;
    margin-bottom: 4vw;
    transition: 0.3s;
    flex-direction: column-reverse;
  }
  .channelItem p {
    color: #fff;
    font-weight: bold;
    font-size: 4.5vw;
    margin-bottom: 4vw;
  }
  .channelItem iframe {
    width: 100%;
    height: 46vw;
    border-radius: 3vw;
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }
  .channelItem img {
    width: 100%;
    margin-right: 0;
    margin-top: 4vw;
  }
}
